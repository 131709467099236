export enum EColumnType {
  // Common cells
  TEXT = "text",
  SQUIRCLE_TEXT = "squircleText",
  DATE = "date",
  DATE_TEXT = "dateText",
  AMOUNT = "amount",
  VAT = "vat",
  VAT_AMOUNT = "vatAmount",
  ACTION = "action",

  // specific cells
  MAIN = "main",
  SPE_TAG = "speTag",
  SPE_VAT = "speVat",
  SPE_AMOUNT = "speAmount",
  SPE_STATUS = "speStatus",
  SPE_DATE = "speDate",
  SPE_LAST_DATE = "speLastDate",
  SPE_TEXT = "speText",
  SPE_BOOLEAN = "speBoolean",
}

export enum EPlaceholderType {
  DOUBLE = "double",
  EMPTY = "empty",
  SIMPLE = "simple",
  SQUIRCLE = "squircle",
  SQUIRCLE_DOUBLE = "squircleDouble",
}

export type ConfigColumn = {
  shouldDisplay: boolean;
  width: number;
  placeholderWidth: number;
  placeholderType: EPlaceholderType;
  justifyContent?: string;
  label?: string;
  path?: string;
  type?: EColumnType;
  data?: string;
  hasHover?: boolean;
  emptyText?: string;
  iconName?: string;
  iconColor?: string;
  dateFormat?: string;
  displayHours?: boolean;
  orderBy?: string;
  useColor?: boolean;
  useErrorColor?: boolean;
  flex?: string;
  hasSymbol?: string;
  dateText?: string;
  tooltip?: string;
  amountDecimalFormat?: boolean;
};
